.procedurePicker {
  border: 2px solid #ddd;
  box-shadow: 0px 3px 6px 0px #00000029;
  margin-bottom: 120px;
  color: #777;
}

.option {
  padding-left: 10px;
  padding-right: 20px;
  flex: 1;
}

.reservationCategoryContainer {
  margin: 0 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .procedure {
    margin-left: 15px;
    margin-right: 35px;

    &:first-of-type {
      padding-top: 15px;
    }

    &:not(:first-of-type) {
      padding-top: 25px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    header {
      font-weight: 700;
      font-size: 16px;

      .length {
        font-size: 14px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 450px) {
    margin: 0 15px;

    .procedure {
      margin-left: 10px;
      margin-right: 30px;
    }
  }
}

.reservationCategoryHeader {
  font-size: 16px;
  padding: 20px 0px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  .expand.categoryOpen {
    transform: rotate(180deg);
  }

  .expand path {
    fill: #777;
  }

  span {
    padding-right: 20px;
  }
}

.workerPicker > header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: #3b4f6a;
  padding-left: 16px;
  margin: 16px 0;
  background-color: #c8c8c81a;
}

.workerPicker > header:hover {
  color: black;
  background-color: #c8c8c830;
}

.workersSummary {
  display: flex;
  align-items: center;
}

.workerPickerSeparator {
  width: 1px;
  background-color: currentColor;
  margin: 12px 0;
  box-sizing: border-box;
}

.workerPickerSideContainer {
  display: flex;
}

.iconContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 8px;
  min-width: 68px;

  .iconTitle {
    font-weight: 400;
  }
}

.workerPicker {
  .icon {
    fill: currentColor;

    &.reverse {
      transform: rotate(180deg);
    }
  }

  .workerList {
    padding-bottom: 16px;

    .serviceEmployee {
      padding: 5px 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
