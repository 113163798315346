.button {
  align-self: flex-end;
}

.content {
  h1 {
    padding-bottom: 24px;
  }

  p {
    line-height: 1.5;
    padding-bottom: 12px;
  }

  p:last-of-type {
    padding-bottom: 48px;
  }

  > ul {
    display: flex;
    justify-content: space-around;

    header {
      font-weight: 700;
    }

    ul {
      padding-left: 16px;
    }

    > li {
      padding-bottom: 32px;

      > header {
        padding-bottom: 12px;
      }
    }

    .procedure {
      padding-left: 16px;

      &:not(:last-child) {
        padding-bottom: 16px;
      }
    }

    @media (max-width: 768px) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
}
