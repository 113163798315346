.employee {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 62px;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: #c8c8c830;
    }
  }

  .employeeAvatar {
    width: 47px;
    height: 47px;
    margin-right: 15px;
    border: 3px solid #ddd;

    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: #3b4f6a;
    }

    .employeePhoto {
      width: 100%;
      height: 100%;
    }
  }

  .employeeDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    .employeeTitle {
      font-weight: 700;
      &:not(:only-child) {
        padding-bottom: 10px;
      }
    }
  }

  .employeePrice {
    font-weight: 700;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    height: auto;
    text-align: center;
    margin: 20px 0;
    border: 2px solid #c8c8c830;
    padding-top: 20px;
    padding-bottom: 20px;

    .employeeAvatar {
      margin-right: 0;
    }
  }
}
