.selectedServicesContainer {
    margin-bottom: 40px;
    color: #777;

    .selectedProcedure:not(:last-child) {
        margin-bottom: 25px;
    }
}

.errorSection {
    align-self: center;

    h1 {
        font-size: 56px;
    }

    .link {
        color: #3b4f6a;
        font-weight: 600;
        cursor: pointer;
        user-select: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .errorText {
        padding-bottom: 30px;
    }

    p {
        padding-bottom: 15px;
    }
}

.categoriesLoader {
    height: 150px;
}

.selectedServicesContainer {
    .selectedProcedure {
        border: 1px solid #ddd;
        display: flex;

        .selectedProcedureDetails {
            padding: 15px;
            flex: 1;
        }

        .removeSelectedProcedure {
            border-left: 1px solid #ddd;
        }

        .topHeading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            font-size: 16px;
            padding-bottom: 15px;

            .length {
                font-size: 14px;
            }

            &.procedureCount {
                font-size: 18px;
            }
        }

        .titles,
        .worker {
            padding-left: 25px;
        }

        .titles {
            padding-bottom: 15px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .category {
                font-size: 14px;
            }
        }

        .worker {
            .sectionTitle {
                font-weight: 700;
                padding-bottom: 10px;
            }
        }

        .removeSelectedProcedure {
            display: flex;
            align-items: center;
            padding: 0 8px;
            cursor: pointer;

            svg path {
                fill: #3b4f6a;
            }
        }
    }

    .addProcedure {
        background-color: #3b4f6a;
        padding: 8px 0;
        margin-top: 15px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        svg path {
            fill: #fff;
        }

        .addProcedureButtonTitle {
            padding-left: 10px;
            color: #fff;
            font-weight: 700;
        }
    }
}

.dateTimeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    margin-top: 30px;
}

.continueControlContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.addProcedureTitle {
    display: flex;
    justify-content: space-between;

    .cancelAddProcedure {
        padding: 30px 5px;
        cursor: pointer;

        svg {
            fill: #3b4f6a;
        }
    }
}

@media (max-width: 720px) {
    .selectedServicesContainer {
        .selectedProcedure {
            flex-direction: row-reverse;

            .selectedProcedureDetails {
                .topHeading {
                    flex-direction: column;
                }

                .titles {
                    flex-direction: column-reverse;
                    padding-left: 0;
                }

                .worker {
                    padding-top: 25px;
                    padding-left: 0;

                    .sectionTitle {
                        text-align: center;
                    }

                    article {
                        border: none;
                    }
                }
            }

            .removeSelectedProcedure {
                border-left: none;
                border-right: 1px solid #ddd;
            }
        }
    }
}
