#root {
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
}

html,
body,
#root {
  min-height: 100vh;
  color: #555;
  background-color: white;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  padding: 50px 0;
}

h2 {
  font-size: 20px;
  font-weight: 700;
  padding: 30px 0;
}

button,
.button {
  text-transform: uppercase;
  border: 1px solid #707070;
  background-color: #3b4f6a;
  border-radius: 50%;
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-weight: 700;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;

  &:disabled {
    background-color: #c3c2c2;
    border-color: #979797;
  }

  &:focus-visible {
    outline-offset: 6px;
    outline-color: black;
  }
}

strong {
  font-weight: 700;
}
