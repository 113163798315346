.mainLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.layoutContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1280px;
    width: 100%;
}

.main-layout main {
    flex: 1;
}

$border-color: #77777733;
$text-color: #555;
$brand-color: #3b4f6a;

.mainHeader {
    border-bottom: 1px solid $border-color;
    display: flex;
    padding: 0 20px;
}

.logo {
    width: 100px;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
}

.mainHeader nav {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.mainHeader nav a {
    text-decoration: none;
    color: $text-color;
}

.mainHeader nav a:hover,
.mainHeader nav a:active {
    text-decoration: underline;
}

.mainHeader nav a.active {
    font-weight: 600;
}

.footerContainer {
    background-color: $brand-color;
    width: 100%;
    display: flex;
    justify-content: center;
}

.mainLayout footer {
    padding: 10px 50px;
    display: flex;
    max-width: 1280px;
    width: 100%;
}

.mainLayout footer .logo {
    width: 100px;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    margin-right: 50px;
}

.mainLayout footer article {
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    flex: 1;
    color: white;
    font-size: 14px;
}

.footerTitle {
    font-size: 20px;
    padding-bottom: 15px;
}

.mainLayout footer article a {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.mainLayout footer article a:first-child {
    margin-bottom: 15px;
}

.mainLayout footer article a:hover {
    text-decoration: underline;
}

.mainLayout footer article a svg {
    width: 25px;
    margin-right: 15px;
}

main {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    padding-bottom: 60px;
}

@media (max-width: 920px) {
    .mainLayout footer {
        flex-direction: column;
        align-items: center;
    }

    .mainLayout footer article {
        max-width: 300px;
        width: 100%;
    }

    .mainLayout footer article:first-of-type {
        padding-top: 40px;
    }

    .mainLayout footer article:last-of-type {
        padding: 50px 0;
    }

    .mainLayout footer .logo {
        margin: 0;
    }
}

@media (max-width: 480px) {
    .mainLayout footer {
        padding: 10px 25px;
    }
}
