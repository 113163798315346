.reservationContacts {
  display: flex;
  flex-direction: row;
  gap: 60px;

  form {
    flex: 1;

    > label,
    > .label {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      font-weight: 700;

      input:not(:read-only),
      textarea {
        margin-top: 20px;
        border: 2px solid #ddd;
        padding: 12px;
        font-size: 16px;
        color: #555;
        font-family: Arial, Helvetica, sans-serif;

        &:focus {
          box-shadow: 0px 0px 8px 1px #ddd;
          outline: none;
        }

        &::placeholder {
          color: #adadad;
        }
      }

      textarea {
        resize: vertical;
        max-height: 500px;
        height: 120px;
      }

      input:read-only {
        font-size: 16px;
        padding: 15px 0;
        outline: none;
        border: none;
        color: #555;
        padding-top: 15px;
        padding-bottom: 10px;
      }

      input[type="checkbox"] {
        appearance: none;
        width: 25px;
        min-width: 25px;
        height: 25px;
        min-height: 25px;
        display: inline-block;
        border: 2px solid #ddd;
        padding: 0;
        margin: 0;

        &::after {
          position: relative;
          content: "";
          display: none;
          height: 12px;
          width: 6px;
          border-bottom: 3px solid #555;
          border-right: 3px solid #555;
          transform: rotate(45deg);
          left: 6px;
        }

        &:focus {
          box-shadow: 0px 0px 8px 1px #ddd;
        }

        &:checked::after {
          display: block;
        }
      }

      .remindCheckboxes {
        margin-top: 15px;
        display: flex;
        flex-direction: row;

        label {
          display: flex;
          align-items: center;
          font-weight: initial;
          margin-right: 15px;

          input {
            margin-right: 10px;
          }
        }
      }
    }

    .agreementCheckbox {
      display: flex;
      flex-direction: row;
      font-weight: initial;
      line-height: 1.5;
      padding-top: 20px;

      input[type="checkbox"] {
        margin-right: 15px;
      }
    }
  }

  aside {
    flex: 1;

    section {
      padding-bottom: 20px;

      header {
        font-weight: 700;
        padding-bottom: 15px;
      }

      ul {
        padding-left: 15px;

        li {
          padding-bottom: 12px;
          line-height: 1.5;

          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.formControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 60px;

  .textualButton {
    all: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 0;
    padding-right: 15px;
    margin-top: 0px;

    svg {
      width: 10px;
      margin-right: 10px;

      path {
        fill: #3b4f6a;
      }
    }

    &:focus-visible {
      outline: 2px solid black;
      border-radius: 6px;
    }
  }
}

@media (max-width: 768px) {
  .reservationContacts {
    flex-direction: column-reverse;
    gap: 10px;
  }
}
